
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');


body {
  --selection-text: #ffffff;
  --selection-background: #ffd4dc;
  --shadow-color:#ffd4dc;
  --blush:#ffc0cb;
  --background-color:#ffffff;
  --seperator-color:#ffc0cb33;
  --nav-background-color:#ffffff8c;
  --text-color:#000000;
  --flower-img: url('../images/flower.svg');
  --about-illustration:url('../images/about-illustration.svg');
  background-color: var(--background-color);
  transition: all 2s;
}
body.dark-mode{
  --selection-text: #ffffff;
  --selection-background: #ffd4dc;
  --shadow-color:#353132;
  --blush:#3b3137;
  --background-color:#181a1b;
  --seperator-color:#ffc0cb33;
  --nav-background-color:#0000001f;
  --text-color:#ffffff;
  --flower-img: url('../images/flower-dark.svg');
  --about-illustration:url('../images/about-illustration-dark.svg');
  background-color: var(--background-color);
  transition: all 2s;


}
*{
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: var(--text-color);
}
.back_arrow{
  
  filter: invert(1);
}
.dark-mode-button{
  background-color: #fff0;
border: none;
width: 2.5em;
height: 2.5em;
object-fit: contain;
overflow: hidden;
cursor: pointer;
}
.dark-mode-button img{
  width: 100%;
  height: 100%;
}
.light-mode-logo{
  filter: invert(1);
}
.flower{
    display: inline-block;
    width: 1em;
    height: 1em;
    background-image: var(--flower-img);
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: -0.1em;
    transition:all 2s;
}

.star{
    position: absolute;
    filter:brightness(0) saturate(100%)  invert(80%) sepia(34%) saturate(191%) hue-rotate(301deg) brightness(102%) contrast(102%);
    opacity: 60%;
    width: 1em;
    height: 1em;
    background-image: url('../images/star.svg');
    background-size: contain;
    background-repeat: no-repeat;
    animation: stars-animation 10s ease-in-out infinite;
    transform: scale(100%);
}

.star-1{
    left : 5em
}
.star-2{
    top: 3em;
    right : 4em
}
.star-3{
    top: 17em;
    right : 2em
}
.star-4{
    top: 8em;
    left : 5em
}
.star-5{
    top: 32em;
    right : 30em
}
.star-6{
    top: 31em;
    left : 23em
}
.star-7{
    bottom: 30em;
    right : 5em
}
.star-8{
    bottom: 30em;
    left : 20em
}
.star-9{
    bottom: 4em;
    left : 6em
}
.star-10{
    bottom: 6em;
    right : 2em
}
.main-section-container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.projects-section-container{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5em;
    padding-top: 2em;
    border-top: 2px var(--seperator-color) solid;
}
.main-title{
    font-size: 4em;
    font-weight: 300;
}
.background{

    position: absolute;
    height: 100%;
    width: 100%;
    filter: blur(10em);
    z-index: -1;
}
.background-circle {
    position: absolute;
    border-radius: 100%;
    background-color: var(--blush);
}
.background-circle-1{
    top: 10em;
    left: 10em;
    width: 20em;
    height: 20em;
}
.background-circle-2{
    bottom: 10em;
    right: 10em;
    width: 15em;
    height: 15em;
}
.divider-flower{
    position: absolute;
    top: -1em;
}
.divider-flower-project{
    position: absolute;
    top: -0.5em;

}
.project-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .project-modal {
    overflow: hidden;
    position: relative;
    height: 80%;
    width: 40em;
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .about-main-section-container{
    width: 100%;
    padding-top: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
  .about-section{

    width: 80%;

  }


  .about-section-2{


    display: flex;
    height: 55%;

  }
  .about-section-2-left{
    width: 70%;
    height: 100%;
    padding: 2em;
    font-size: 1.1em;
    padding-right: 7em;
    line-height: 1.5;

  }
  .about-section-2-left h4{
    font-size: 1em;

  }
  .about-section-2-right{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .about-section-3{
    position: relative;
    padding-top: 2em;
    border-top: 2px rgba(255, 192, 203, 0.200) solid;
    padding-left: 5em;
    margin-top: 2em;
    display: flex;
    justify-content: center;
    height: 30%;
  }
  .about-section-3-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .about-section-3-right h1{
    font-size: 1.5em;
  }
  .about-section-3-left h1{
    font-size: 1.5em;
  }
    .about-section-3-left{
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 50%;
    }



  ::-moz-selection { 
    color: var(--selection-text);
    background: var(--selection-background)
  }
  
  ::selection {
    color: var(--selection-text);
    background: var(--selection-background)
  }


  .chip{
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5em;
    padding: 0.8em;
    padding-bottom: 0.9em;
    border-radius: 1em;
    font-size: 0.9em;
    text-align: center;
    border: 1px solid black;
    box-shadow: 4px 4px 0px 1px var(--shadow-color);
    -webkit-box-shadow: 4px 4px 0px 1px var(--shadow-color);
    -moz-box-shadow:4px 4px 0px 1px var(--shadow-color);
    transition: all 1s;

  }
  .chip:hover{
    transform: scale(105%);
  }
  .chip-container{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
  }
  .divider-flower-about{
    position: absolute;
    top: -0.5em;

}

.modal-back{
  display: flex;
  justify-content: flex-start;
  gap: 0.5em;
  align-items: center;
  margin-bottom: 2em;
}
.modal-thumbnail{
  position: absolute;
  width: 100%;
  bottom: -8em;
  transition: all 2s;
}
.badge{
  width: 10em;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}
.modal-title{
  display: flex;
  gap: 0.5em;
  align-items: center;
  font-size: 2em;
  margin-bottom: 0.7em;
}
.modal-logo{
  width: 1em;
  height: 1em;
}
.modal-description{
  margin-bottom: 2em;
}
.modal-description p{
  line-height: 1.4em;
}

.image-slide-in {
  opacity: 1;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out; /* Adjust the duration and timing function as desired */
}

  @keyframes stars-animation {
    0%{
        margin-top: 0em;
       transform: scale(100%);
    }
    50%{
        margin-top: -1em;
        transform: scale(120%);
    }
    100%{
        margin-top: 0em;
        transform: scale(100%);
    }
}
.about-section-2-left{
  overflow-y: auto;
}

.about-illustration{
  background-image: var(--about-illustration);
  width: 30vw;
  height: 20em;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 4em;
background-position: center;  
}
.about-section-2-left h4{
  font-size: 0.9vw;
}
.project-modal-main-container{
  position: relative;
  width: 100%;
  z-index: 10;
  background-color: var(--background-color);
  opacity: 98%;
  padding-bottom: 2em;
}

@media screen and (max-width: 900px) {
  .background-circle{
    display: none;
  }
  .star{
    display: none;
  }
  .background-circle-1 {
    width: 20em;
    height: 20em;
  }
  .modal-thumbnail{
    position:initial;
  }
  .modal-title{
    font-size: 1.3em;
  }
  .modal-back h3{
    font-size: 0.8em;
  }
  .modal-description{
    font-size: 0.7em;
  }
  .chip{
    font-size: 0.6em;
  }
  .project-modal{
    padding: 10px;
  }
  .badge{
    width: 5em;
  }
  .main-title{
    font-size: 2em;
  }
  .about-section-2{
    padding: 0em;
    flex-direction: column;
  }
  .about-section-2-left{
    padding: 0em;

    width: 100%;
    flex-direction: column;

  }
  .about-section-2-right{
    display: none;
  }
  
  .about-section-2-right h4{
    overflow: scroll;
  }
  .about-section-3{
    padding: 0em;
    padding-top: 2em;
    height:30%;
  }
  .chip-container{
    overflow-y: auto;
    overflow-x: hidden;
  }
  .about-section-3 h1{
    font-size: 0.5em;
  }
  .about-section-2-left h4{
    font-size: 0.7em;
  }
  .about-section-2-left h1{
    font-size: 1.3em;
  }

}
@media screen and (max-width: 350px) {
  .main-title{
    font-size: 1.1em;
  }

  
}