*{
    font-weight: 300;
}
.nav-container{

    width: 100%;
    height: 5em;
    position: fixed;
    display: flex;
    z-index: 10;
    align-items: center;
    background-color: var(--nav-background-color);
    font-size: 0.7em;

    
}
.nav-items{
    
    position: absolute;
    right: 1em;
    gap: 1em;
    display: flex;
    align-items: center;
    
}

.nav-logo{
    margin-left: 1em;
    justify-self: flex-start;
    align-items: center;
}
.nav-item{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
}

.nav-selected .nav-item-background{
    filter: blur(1em);
    position: absolute;
    background-color: var(--blush);
    width: 2em;
    height:2em;
    z-index: -1;
}

@media screen and (max-width: 900px) {
    .nav-item-text {
      font-size: 1em;
    }
    .dark-mode-button{
        width: 1.5em;
        height: 1.5em;
    }
    .nav-logo-text{
        display: none;
    }
    
  }