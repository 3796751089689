.footer-container{
    margin-top: 2em;
    width: 100%;
    height: 5em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sub-footer-container{
    padding-top: 1em;
    border-top: 2px var(--seperator-color) solid;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer-items{
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding: 1em;

}
.footer-item-text{
    font-size: 1em;
}
.footer-link{
    text-decoration: none;
    color: black;
}
.footer-item-text {
    font-size: 1em; /* default font size */
  }
  
  .footer-item:hover .footer-item-text {
    font-size: 1em; /* increased font size on hover */
  }
  .tooltip{
    position: absolute;
    background-color: var(--background-color);
    border-radius: 1em;
    padding: 1em;
    margin-bottom: 7em;
  }
  @media screen and (max-width: 900px) {
    .footer-item-text{
        font-size: 0.6em;
    }
    
  }