.project-container{
    cursor: pointer;
    border: 1px solid rgba(128, 128, 128, 0.11);
    width: 80%;
    margin: 1em;
    background-color: var(--background-color);
    height: 25em;
    border-radius: 3em;
    padding: 2px;
    display: flex;
    box-shadow: 14px 14px 0px 1px var(--shadow-color);
    -webkit-box-shadow: 14px 14px 0px 1px var(--shadow-color);
    -moz-box-shadow: 14px 14px 0px 1px var(--shadow-color);
transition: 1s all;
overflow: hidden;
}
.project-container:hover{
    box-shadow: 5px 4px 0px 1px var(--shadow-color);
-webkit-box-shadow: 5px 4px 0px 1px var(--shadow-color);
-moz-box-shadow: 5px 4px 0px 1px var(--shadow-color);
    transform: scale(102%);
}

.project-container-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    padding-left: 5em;
    padding-right: 10em;
}
.project-container-right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}
.project-container-image{
    transform: scale(18%);
}
.project-name{
font-size: 3em;
margin-bottom: 1em;
}
.project-description{
    font-size: 1.5em;
    margin-bottom: 1em;
}
.project-logo{
    width: 1em;
    height: 1em;
}
.highlight-stars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-image: url('../../images/flower.svg'); /* Replace with your star image or other visual element */
    background-size: contain;
    opacity: 0;
  }
  @media screen and (max-width: 900px) {
    .project-container{
        padding: 0px;
        height: 30em;
        flex-direction: column;
    }
    .project-container-left{
        width: 100%;
        padding: 1em;
        justify-content: center;
        align-items: center;
    }
    .project-container-right{
        width: 100%;
        padding: 0px;
        justify-content: center;
        align-items: center;
    }
    .project-name{
        font-size: 1.6em;
    }
    .project-description{
        font-size: 1em;
    }
    .project-container-image{
        transform: scale(8%);
    }
    
  }